@import 'src/styles/variables';

.checkbox {
  border: 1px solid $border-color;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;

  &.checked {
    &:after {
      position: absolute;
      top: -2px;
      left: 4px;
      content: '';
      display: block;
      width: 8px;
      height: 14px;
      border: solid $blue;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}

.title {
  display: inline-block;
  vertical-align: middle;
}

.checkboxHolder {
  display: inline-block;
}
