@import 'src/styles/variables';
@import 'src/styles/tables';
@import 'src/styles/forms';

.tableBlock {
  margin-bottom: 50px;
  height: 250px;
  overflow-y: auto;

  .cuTable {
    th, td {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 14px;
    }
  }
}

.modalBlock {
  padding-bottom: 30px;
  min-height: 55px;

  p:Last-child {
    margin-bottom: 0;
  }
}

table th.thIndex {
  width: 40px;
}

table tr td.textLeft:last-child, table tr th.textLeft:last-child {
  text-align: left;
}

table.cuTable th.thError {
  width: 140px;
  text-align: left;
}

table th.thName {
  width: 120px;
}

.errorCol {

  div {
    display: block;
    line-height: 1.2;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}