.cFooter {
  min-height: 166px;
  background: #fff;
  flex: 0 0 auto;
  margin-top: 10px;

  &:before {
    display: block;
    content: "";
    height: 3px;

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fbd020+0,fbd020+8,009348+8,009348+25,d8232a+25,d8232a+44,00a3da+44,00a3da+100 */
    background: #fbd020; /* Old browsers */
    background: -moz-linear-gradient(left, #fbd020 0%, #fbd020 8%, #009348 8%, #009348 25%, #d8232a 25%, #d8232a 44%, #00a3da 44%, #00a3da 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #fbd020 0%, #fbd020 8%, #009348 8%, #009348 25%, #d8232a 25%, #d8232a 44%, #00a3da 44%, #00a3da 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #fbd020 0%, #fbd020 8%, #009348 8%, #009348 25%, #d8232a 25%, #d8232a 44%, #00a3da 44%, #00a3da 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fbd020', endColorstr='#00a3da', GradientType=1); /* IE6-9 */

  }

  & .cContent {
    padding: 25px 120px;
  }
}

.cFooterTop {
  padding: 0 0 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bottomMenu {
  display: block;
  padding: 0;
  margin: 0;
}

.bottomMenu li {
  display: inline-block;
}

.bottomMenu li:after {
  content: "|";
  font-size: 11px;
  line-height: 15px;
  color: #333;
  padding: 0 5px;
}

.bottomMenu li:last-child:after {
  content: "";
}

.bottomMenu li a {
  font-family: 'OpenSans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: #333;
  text-decoration: none;
}

.footerLogo {
  width: 77px;
}

.cFooterBottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.copyright {
  font-family: 'OpenSans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  text-align: right;
  color: #666666;
}