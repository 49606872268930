@import 'src/styles/variables';

.modal {
  background: $white;
  border-radius: 4px;
  padding: 40px 52px;
  position: relative;

  h2 {
    margin-bottom: 45px;
    font-weight: 300;
    font-size: 26px;
    line-height: 31px;
    font-family: 'RobotoLight', sans-serif;
    color: #333333;
    margin-top: 0;
  }
}

.modalOverlay {
  background-color: $overlayBackgroundColor;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.closeButton {
  position: absolute;
  outline: none;
  top: 33px;
  right: 40px;
  width: 24px;
  height: 24px;
  border: 0;
  background: none;
  cursor: pointer;
  &:after, &:before {
    content:'';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background: rgba(51, 50, 56, 0.52);
    transform-origin: center;
  }
  &:after {
    transform: rotate(45deg);
  }
  &:before {
    transform: rotate(-45deg);
  }
}
