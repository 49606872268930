@import 'src/styles/tables';

.cContent {
  margin-top: -50px;
    .btnWrap {
    .btn {
      margin-right: 20px;
    }
  }
}

.TopContentBar_btn__d8RiL {
  margin-right: 20px;
}

.statusCol {
  width: 225px; 
}

.payrollCheckbox {
  white-space: nowrap;
  overflow: hidden;
}

.pausedText {
  margin-left: 10px;
}