@import 'src/styles/variables';
@import 'src/styles/forms';

.minHeight {
  min-height: 430px;
}

.descriptionText {
  margin-top: -30px;
  margin-bottom: 50px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #4C4C4C;
}