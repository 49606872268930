.cHeader {
  padding: 15px 135px;
  min-height: 104px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.03);
}

.logo {
  display: block;
}

.cRight {
  min-width: 167px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  position: relative;
}

.cLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}