@import 'src/styles/variables';
@import 'src/styles/forms';

.cText {
  margin-top: 20px;
  color: $red;
}

.minHeight {
  min-height: 150px;
}

.errLink {
  color: $base-link-color;
}