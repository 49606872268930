.appContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.cContent {
  min-width: 800px;
}

