@import 'src/styles/variables';
@import 'src/styles/tables';
@import 'src/styles/forms';

.cTestMapping {
    .testMappingInfo {
        margin: 60px 0 70px;
        color: $text-color;
    }

    label.cuButton {
        margin-bottom: 0;
    }
}
