@import 'src/styles/variables';

.colDateJoined {
  width: 15%;
  min-width: 170px;
}

.colName {
  width: 30%;
}

.colNino {
  width: 15%;
  min-width: 170px;
}

.colPayGroupSelect {
  width: 20%;
}

.colStatusSelect {
  width: 15%;
}

.reasonCol {
  width: 20%;
}