// Colors
$text-color: #4C4C4C;
$border-color: #AAA;
$border-color-light: #E3E3E3;
$black: #333;
$gray: #737373;
$gray-light: #F5F7FA;
$primary-color: #0076D6;
$placeholder-color: #c0c0c0;
$backgroud-light: #d4f0ff;

$green: #008844;
$green-2: #68b183;

$red: #D80000;
$red-2: #f56679;

$white: #fff;
$black: #000;
$blue: #0094df;

$overlayBackgroundColor: rgba(43, 46, 65, 0.62);

// Body
$base-text-color: $black;
$font-size-base: 10px !default;
$font-size-default: 1.6rem !default;
$font-size-undersized: 1.3rem !default;
$font-size-small: 1.4rem !default;
$line-height-base: 1.625 !default;
$base-font-family: 'Roboto', sans-serif;

// Liks
$base-link-color: $blue;

// Headers
$h1-font-size: 26px !default;
$h2-font-size: 24px !default;
$h3-font-size: 22px !default;
$h4-font-size: 20px !default;
$h5-font-size: 19px !default;
$h6-font-size: 18px !default;

$headings-font-family: inherit !default;
$headings-color: $base-text-color !default;

$animation-speed: 0.3s;

// z-index
$workspace-decor-z-index: -1;
$max-z-index: 9999;

// other
$component-line-height: 1.25;

