@import 'src/styles/tables';

.cTerms {
  background: #fff;
  padding: 32px 43px;
  color: #4C4C4C;

  .mainHeading h1 {
    font-family: 'RobotoLight', sans-serif;
    color: #4C4C4C;
  }

  h2 {
    font-family: 'RobotoLight', sans-serif;
    margin-top: 30px;
  }

  p {
    font-family: 'RobotoLight', sans-serif;
    font-size: 18px;
    line-height: 1.6;
  }

  ol li {
    font-family: 'RobotoLight', sans-serif;
    font-size: 18px;
    line-height: 1.6;
    padding-bottom: 30px;
  }

  ul li {
    font-family: 'RobotoLight', sans-serif;
    font-size: 18px;
    line-height: 1.6;
    list-style: none;
  }

  ul li:before {
    content: '\2022';
    font-size: 1.5rem;
    color: #09c;
    padding-right: 15px;
    margin-left: -23px;
    position: relative;
    top: 2px;
    line-height: 1;
  }

  ol li ol li:last-child {
    padding-bottom: 0;
  }

  h3 {
    font-family: RobotoMedium, arial, sans-serif;
    font-size: 22px;
    margin-top: 30px;
  }

  .mainHeading h1.underline:after {
    content: '';
    height: 2px;
    border-bottom: 3px solid #0094df;
    width: 38px;
    display: block;
    margin: 38px 0;
    position: relative;
  }

  .cuLink {
    color: #0094DF;
    text-decoration: underline;
  }

  .tTerms tr td {
    vertical-align: top;
  }

  .tTerms tr th {
    font-weight: bold;
  }

  .tTerms tr td:last-child, .tTerms tr th:last-child {
    text-align: left;
  }
}