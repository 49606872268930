@import 'src/styles/variables';
@import 'src/styles/forms';

.minHeight {
  min-height: 450px;
}

.subDescription {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $gray;
}

.leftPositionText {
  min-width: 300px;
  padding-top: 30px;
}

.cPicker {
  display: flex;
  flex-direction: column;
}

.cuDatePicker {
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  padding: 0 15px;
  height: 42px;
  margin-top: 10px;
  display: block;
  border: 1px solid $border-color;
  border-radius: 2px;
  outline: none;

  &::placeholder {
    color: $text-color;
    opacity: 1;
  }
}

.react-datepicker {
  border-radius: 2px;
}

.clearLink {
  margin-bottom: 20px;
  font-size: 16px;
  color: $primary-color;
  margin-right: 30px;
  text-decoration: none;
  font-family: RobotoMedium, sans-serif;
}



