.cBanner {
  height: 382px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background: #004e86;
  background: -moz-linear-gradient(45deg,  #004e86 0%, #004e86 41%, #0076d6 100%);
  background: -webkit-linear-gradient(45deg,  #004e86 0%,#004e86 41%,#0076d6 100%);
  background: linear-gradient(45deg,  #004e86 0%,#004e86 41%,#0076d6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#004e86', endColorstr='#0076d6',GradientType=1 );
}

ul.cBreadCrumbs {
  background: rgba(0, 0, 0, 0.1);
  padding: 13px 32px;
  margin: 0;
  color: #fff;
  font-size: 13px;
}

.cBreadCrumbs li {
  display: inline-block;
  padding-right: 10px;
}

.cBreadCrumbs li:before {
  content: "/";
  margin-right: 10px;
}

.cBreadCrumbs li:first-child:before {
  content: "";
  margin-right: 0;
}

.cBreadCrumbs li a {
  color: #fff;
}

.bcIcon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.bcActive {
  text-decoration: underline;
}

h1.mainTitle {
  color: #fff;
  font-family: 'RobotoLight', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 64px;
  letter-spacing: -0.5px;
  margin: 64px 32px 0;
}

h2.subTitle {
  color: #fff;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 18px 32px 0;
}

.cTabs {
  position: absolute;
  left: 32px;
  right: 32px;
  top: 350px;
  padding: 0;
  list-style: none;
  display: flex;
  border-bottom: 1px solid rgba(255,255,255,0.2);

  & li {
    display: inline-block;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin-right: 80px;
    cursor: pointer;
    padding-bottom: 10px;
    border-bottom: 3px solid transparent;

    &.active {
      border-bottom: 3px solid #fff;
    }
  }
}