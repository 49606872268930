.cFooter {
  min-height: 208px;
  background: #fff;
  padding: 30px 120px;
  flex: 0 0 auto;
}

.cFooterTop {
  padding: 30px 0 23px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footerSubMenu {
  display: block;
  width: 500px;
  margin: 0;
  padding: 0;
}

.footerSubMenu li {
  display: inline-block;
  width: 50%;
}

.footerSubMenu li a {
  font-size: 14px;
  line-height: 28px;
  color: #666;
  text-decoration: none;
}

.bottomMenu {
  display: block;
  padding: 0;
  margin: 0;
}

.bottomMenu li {
  display: inline-block;
}

.bottomMenu li:after {
  content: "|";
  font-size: 11px;
  line-height: 15px;
  color: #666666;
  padding: 0 5px;
}

.bottomMenu li:last-child:after {
  content: "";
}

.bottomMenu li a {
  font-family: 'OpenSans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: #666666;
  text-decoration: none;
}

.cFooterBottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.copyright {
  font-family: 'OpenSans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  text-align: right;
  color: #666666;
}