.field {
  margin-bottom: 20px;
  input {
    margin-bottom: .5rem;
  }
}

label {
  font-size: 16px;
}

.cControlsLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.cControlsLeftWrapped {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 685px;
}

.cControlsCenter {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.leftPosition {
  min-width: 300px;
  margin-right: 40px;
}

.leftPositionPadd {
  min-width: 300px;
  margin-right: 10px;
  margin-bottom: 0 !important;
}

.centerPosition {
  margin-right: 40px;
}

.cControlsCenter .centerPosition:last-child {
  margin-right: 0;
}

.cButtonRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.error {
  color: $red;
}

.serverError {
  color: $red;
  margin-top: -20px;
}

.cPopupContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #4C4C4C;
  margin-top: 60px;
}

.subTitle {
  display: block;
  margin-top: 30px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #4C4C4C;
}

.loader {
  padding-bottom: 30px;
}

.cuLink {
  color: #0094DF;
  text-decoration: underline;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  display: inline-block;
  position: relative;
  margin-right: 20px;
}

.redLink {
  color: #444444;
  margin-top: 20px;
}

.deleteBtn {
  position: relative;
  width: 18px;
  height: 18px;
  font-size: 34px;
  line-height: 18px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  margin-top: 20px;
  border-radius: 2px;
  &:after, &:before {
    content:'';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background: #0094DF;
    transform-origin: center;
  }
  &:after {
    transform: rotate(45deg);
  }
  &:before {
    transform: rotate(-45deg);
  }
}

.margBottom {
  margin-bottom: 0;
}

.cScroll {
  height: 400px;
  overflow-y: auto;
}

.cLinks {
  display: block;
  overflow: hidden;
}

.tcbTitle {
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  display: block;
  padding: 0 0 12px;
  color: $black;
  margin: 0;
}

.topContentBar {
  padding-bottom: 22px;
  border-bottom: 1px solid #E3E3E3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.longParagraph {
  line-height: 24px;
  color: #707070;
}

input[type="file"].uploadFile {
  opacity: 0;
  position: absolute;
  z-index: -1;
  display: none;
}

.cuButton {
  background-color: $primary-color;
  padding: 0.4rem 1rem;
  height: 2.4rem;
  min-width: 176px;
  font-size: 1rem;
  line-height: $component-line-height;
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 15px;
  border: 2px solid $primary-color;
  font-family: RobotoMedium, sans-serif;
  outline: none;
  color: $white;
  text-align: center;
}