@import 'src/styles/variables';
@import 'src/styles/forms';

.tcbActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payrollbtn {
  margin-left: 20px;
}

.btn {
  font-family: RobotoMedium, sans-serif;
  display: inline-block;
  min-width: 157px;
  background: $primary-color;
  border-radius: 2px;
  color: $white;
  font-size: 16px;
  line-height: 24px;
  padding: 9px 35px;
  text-align: center;
  text-decoration: none;
}

.searchWrap {
  width: 212px;
  margin-left: 20px;

  input {
    margin: 0;
  }
}

.input {
  border: 1px solid $border-color;
  border-radius: 2px;
  line-height: $component-line-height;
  height: 42px;
  padding: 11px 16px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 16px;

  &::placeholder {
    color: $text-color;
    opacity: 1;
  }

  &:disabled {
    background: #e9e9e9;
  }
}

.medium {
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  padding: 0 15px;
  height: 42px;
  margin-top: 10px;

  &::placeholder {
    color: $placeholder-color;
    opacity: 1;
  }
}
