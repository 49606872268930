@import 'src/styles/variables';

%link {
  padding: 0;
  border: 0;
  background: none;
  min-width: 0;
  height: auto;
  text-decoration: underline;
}

.button {
  background-color: $white;
  min-width: 176px;
  line-height: $component-line-height;
  border-radius: 2px;
  height: 42px;
  padding: 9px 16px;
  cursor: pointer;
  margin-bottom: 15px;
  border: 2px solid $black;
  font-size: 15px;
  font-family: RobotoMedium, sans-serif;
  outline: none;

  &.fullWidth {
    width: 100%;
  }

  &.disabled {
    background-color: #c0c0c0 !important;
    cursor: not-allowed;
    color: $white !important;
  }

  &.primary {
    background-color: $primary-color;
    color: $white;
    border-color: transparent;
    font-weight: 500;
  }

  &.rightArrow {
    background-color: $primary-color;
    color: $white;
    border-color: transparent;
    font-weight: 500;
    position: relative;
    text-align: left;
    padding-right: 30px !important;
    min-width: 168px !important;
    box-sizing: border-box !important;
    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 15px;
      width: 20px;
      top: 11px;
      right: 0;
      background: url("/public/images/chevron-right-dark.svg") no-repeat;
    }
  }

  &.link {
    @extend %link;
    color: $blue;
  }

  &.linkAlt {
    @extend %link;
    color: #bbb;
  }

  &.small {
    font-size: 1rem;
  }

  &.xsmall {
    padding: 0;
    height: 3.4rem;
  }

  &.xxsmall {
    padding: 0.4rem 1rem;
    height: 2.4rem;
    min-width: 176px;
    font-size: 1rem;
  }

  &.greenButton {
    background-color: $green;
  }
}
