@import 'src/styles/variables';

.loginWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 10px;
}

.loginHolder {
  background-color: $white;
  text-align: center;
  width: 100%;
  max-width: 451px;
  min-height: 672px;
  border-radius: 4px;
  box-shadow: 0 0 7px 0 rgba($black, 0.05), 1px 1px 2px 0 rgba($black, 0.05);

  ol {
    padding-left: 5rem;
    margin-bottom: 2rem;
    text-align: left;
  }

  h1 {
    margin-bottom: 20px;
    &:after {
      display: none;
    }
  }

  .contentHolder {
    padding: 0 70px 20px;
    margin-top: -25px;
    position: relative;
  }

  img {
    width: 100%;
  }
}
