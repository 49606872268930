@import 'src/styles/variables';
@import 'src/styles/forms';
@import 'src/styles/tables';

.cContent {
  margin-top: -50px;
}

.sftpConfigurationForm {
  margin-top: 30px;
}

.sftpSettingsContainer {
  flex-wrap: wrap;
  & > * {
    flex: 0 0 calc(25% - 15px);
    flex-grow: 1;
    margin: 15px 0;
    min-width: 300px;
  }
  & > *:first-child {
    label > div {
      max-width: 300px;
    }
  }
}
.csvMappingContainer {
  display: flex;
  & > * {
      flex-basis: 50%;
  }
}

.csvColumnLabel {
  margin-top: 35px;
  min-width: 170px;
}

.columnIndexSelect {
  min-width: 120px;
  margin-bottom: 40px !important;
}

.sftpAddress {
  display: block;
  margin-bottom: 20px;
}

.shortSelect {
  label > div {
    max-width: 400px;
  }
}

.testMappingButton {
  margin-right: 20px;
  & input {
    display: none;
  }
  &, & * {
    cursor: pointer;
  }
}