@import 'src/styles/tables';

.cContent {
  margin-top: -50px;
}

.inputsRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 970px;
  padding-bottom: 38px;

  &:first-child {
    padding-top: 41px;
  }

  .rowItem{
    width: 300px;
    margin-right: 34px;

    &:last-child {
      margin-right: 0;
    }
  } 
}

.mappingWrap {
  display: flex;
  justify-content: space-between;
  max-width: 970px;
  padding-top: 41px;

  .formCol {
    width: 35%;
  }
}

.selectGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .selectSmall {
    width: 117px;
  }
}

.btnsContainer {
  display: flex;
  justify-content: space-between;

  .btnsWrap {
    width: 45%;

    &:only-child {
      width: 100%;;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }
}

.btnsWrap {
  display: flex;

  button {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }

    &:only-child {
      align-self: flex-start;
    }
  }
}

.cButtons {
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

.errorBox {
  min-height: 50px;
}

.cLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
}

.cTextBox {
  .textBox {
    margin-top: 20px;
  }
}