@import 'src/styles/variables';

.input {
  border: 1px solid $border-color;
  border-radius: 2px;
  line-height: $component-line-height;
  height: 42px;
  padding: 11px 16px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 16px;

  &::placeholder {
    color: $text-color;
    opacity: 1;
  }

  &:disabled {
    background: #e9e9e9;
  }
}

.medium {
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  padding: 0 15px;
  height: 42px;
  margin-top: 10px;

  &::placeholder {
    color: $text-color;
    opacity: 1;
  }
}