@import 'src/styles/tables';

.heading {
  margin: 0 0 20px;
  color: #464646;
  font-family: 'RobotoLight', sans-serif;
  font-size: 36px;
  line-height: 1;
  font-weight: 300;
}

.payrollTable th.colWithBtn {
  padding-right: 90px;
}