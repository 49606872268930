@import 'src/styles/tables';

.cContent {
  margin-top: -50px;
}

.fileCol {
  width: 40%;
}

input[type="file"].uploadFile {
  opacity: 0;
  position: absolute;
  z-index: -1;
  display: none;
}

th.thStatus {
  text-align: right;
  min-width: 120px;
}