@import 'src/styles/tables';
@import 'src/styles/hr_tables';

.cContent {
  margin-top: -50px;
}

.newLoader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}