@import 'src/styles/variables';

.select {
  margin-top: 10px;
  text-align: left;
  
  :global {
    .selectHolder__control {
      border: 1px solid $border-color;
      border-radius: 2px;
      box-shadow: none;

      &:hover {
        border-color: $border-color;
      }
    }

    .selectHolder__value-container {
      margin-left: 8px;
      margin-right: 4px;
    }

    .selectHolder__indicator-separator {
      background-color: #DCDCDC;
    }

    .selectHolder__indicator {
      position: relative;
      width: 42px;
      height: 100%;
      padding: 0 15px;

      &:after {
        position: absolute;
        content: "";
        top: 13px;
        width: 10px;
        height: 10px; 
        border-bottom: 2px solid $black; 
        border-right: 2px solid $black;
        transform: rotate(45deg);
      }

      svg {
        display: none;
      }
    }

    .selectHolder__placeholder {
      color: $text-color;
      line-height: 17px;
    }

    .selectHolder__control--is-disabled {
      & .selectHolder__placeholder {
        color: hsl(0,0%,60%);
      }
    }

    .selectHolder__menu {
      width: 100%;
      top: 0;
      right: 0;
      margin: 0;
      box-shadow: 0px 5px 5px rgba($gray, 0.15), 0px 3px 14px rgba($gray, 0.1), 0px 8px 10px rgba($gray, 0.1);
    }

    .selectHolder__menu-list {
      height: 235px;
      padding: 8px 0;
    }

    .selectHolder__option {
      padding: 12px 17px;
      color: rgba($black, 0.87);
    }

    .selectHolder__option--is-focused,
    .selectHolder__option:active,
    .selectHolder__option--is-selected {
      background-color: rgba($black, 0.08);
    }

    .selectHolder__option--is-selected {
      font-weight: 700;
    }
  }
}

table .select {
  margin-top: 0;
}