@import 'src/styles/variables';
@import 'src/styles/forms';


.centerPart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: RobotoLight, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 42px;
  text-align: center;
  color: #4C4C4C;
  padding-bottom: 40px;
  min-height: 250px;

  & img {
    margin-bottom: 40px;
  }
}
