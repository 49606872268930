@import 'src/styles/variables';
@import 'src/styles/forms';

.cText {
  margin-top: 20px;
}

.minHeight {
  min-height: 200px;
}

.errorOffset {
  margin-top: 10px;
}