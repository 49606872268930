@import 'src/styles/variables';

.field {
  margin-bottom: 30px;
  input {
    margin-bottom: .5rem;
  }
}

.error {
  color: $red;
}

.leftPosition {
  margin-top: 50px;
}

.input {
  border: 1px solid $border-color;
  border-radius: 2px;
  line-height: $component-line-height;
  height: 4.2rem;
  padding: 1.1rem 1.6rem;
  width: 100%;
  margin-bottom: 2rem;
  font-size: 26px;

  &::placeholder {
    color: #c0c0c0;
    opacity: 1;
  }

  &:disabled {
    background: #e9e9e9;
  }
}

.medium {
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  padding: 0 15px;
  height: 42px;
  margin-top: 10px;

  &::placeholder {
    color: #c0c0c0;
    opacity: 1;
  }
}


