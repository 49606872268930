@import 'src/styles/variables';

.pageWrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #F7F7F7;
}

.cPageContent {
  width: 698px;
  min-height: 702px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 32px auto 100px;
  box-sizing: border-box;
  padding: 30px 60px 40px;
  color: $text-color;
  font-family: Roboto, sans-serif;
}

.subTitle {
  color: $black;
  font-family: RobotoMedium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 10px;
}

.сTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & h3 {
    font-family: RobotoLight, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 42px;
    color: #333333;
    margin: 0 0 0 25px;
  }
}

.topText {
  min-height: 183px;
  box-sizing: border-box;
  padding: 50px 30px 40px;
}

.cMiddle {
  flex: 1;

  ol, ul {
    padding-left: 45px;
  }

  ul {
    padding-left: 30px;
  }

  ol li, ul li {
    margin-top: 30px;
    position: relative;

    &:before {
      content: "";
      display: inline-block;
      width: 30px;
      height: 30px;
      position: absolute;
      background: url("/public/images/check.svg") no-repeat;
      left: -50px;
    }
  }

  ul li {
    list-style: none;

    &:before {
      left: -40px;
    }
  }

  .offsetTop {
    margin-top: 30px;
  }

  .cuBtnOffset {
    margin-bottom: 0;
  }
}

.cBottom {
  display: flex;
  justify-content: flex-end;
}

