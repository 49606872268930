@import 'src/styles/variables';

.field {
  margin-bottom: 30px;
  input {
    margin-bottom: .5rem;
  }
}

.error {
  color: $red;
}

.leftPosition {
  margin-top: 50px;
}



