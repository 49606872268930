@import 'src/styles/variables';

.cSelect {
  position: relative;
}

.cItems {
  position: absolute;
  background: white;
  display: block;
  list-style: none;
  margin: 0;
  border: 1px solid rgba(0,0,0,0.1);
  min-width: 156px;
  right: -50px;
  top: -10px;
  overflow: hidden;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 8px 1px rgba(0,0,0,0.07);
  box-shadow: 0 3px 8px 1px rgba(0,0,0,0.07);
  padding: 5px 0;
  z-index: 999;
}

.cItems li {
  text-align: left;
  padding: 10px 15px;
  display: block;
  cursor: pointer;
}

.cItems li:hover {
  background: rgba(0,0,0,0.1);
}

.coverLayer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.detailsLink {
  padding: 10px;
}