@import 'src/styles/variables';

.form {
  max-width: 270px;
  margin: 0 auto;
}

.error {
  color: $red;
}

.cuTitle {
  font-family: RobotoLight, sans-serif;
  font-weight: normal;
  font-size: 26px;
  color: #333;
  margin: 0;
}

.cuSubTitle {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.625;
  color: #000;
  margin: 0 0 35px;
}

.cuLink {
  color: #0094DF;
}

.orSection {
  margin-top: 10px;
  margin-bottom: 25px;
}

.marginTop {
  margin-top: 5px;
}