@import 'src/styles/tables';

.cContent {
  margin-top: -50px;
}

.colPayrollName {
  width: 25%;
}

.colSortCode {
  width: 15%;
}