code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-Regular-webfont.eot');
  src: url('./fonts/roboto/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('./fonts/roboto/Roboto-Regular-webfont.woff') format('woff'),
  url('./fonts/roboto/Roboto-Regular-webfont.ttf') format('truetype'),
  url('./fonts/roboto/Roboto-Regular-webfont.svg#robotoregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoMedium';
  src: url('./fonts/roboto/Roboto-Medium-webfont.eot');
  src: url('./fonts/roboto/Roboto-Medium-webfont.eot?#iefix') format('embedded-opentype'),
  url('./fonts/roboto/Roboto-Medium-webfont.woff') format('woff'),
  url('./fonts/roboto/Roboto-Medium-webfont.ttf') format('truetype'),
  url('./fonts/roboto/Roboto-Medium-webfont.svg#robotomedium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoLight';
  src: url('./fonts/roboto/Roboto-Light-webfont.eot');
  src: url('./fonts/roboto/Roboto-Light-webfont.eot?#iefix') format('embedded-opentype'),
  url('./fonts/roboto/Roboto-Light-webfont.woff') format('woff'),
  url('./fonts/roboto/Roboto-Light-webfont.ttf') format('truetype'),
  url('./fonts/roboto/Roboto-Light-webfont.svg#robotomedium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/opensans/OpenSans-Regular-webfont.eot');
  src: url('./fonts/opensans/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('./fonts/opensans/OpenSans-Regular-webfont.woff') format('woff'),
  url('./fonts/opensans/OpenSans-Regular-webfont.ttf') format('truetype'),
  url('./fonts/opensans/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

body,html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background: #F5F7FA;
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

p {
  margin: 0 0 15px;
}