@import 'src/styles/tables';
@import 'src/styles/hr_tables';

.cContent {
  margin-top: -50px;
}

.newLoader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.colEmployee {
  &UploadDate {
    width: 15%;
    min-width: 190px;
  }

  &Action {
    width: 110px;
  }
}

input[type="file"].uploadFile {
  opacity: 0;
  position: absolute;
  z-index: -1;
  display: none;
}
