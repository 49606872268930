@import 'src/styles/variables';

.cContent {
  background: $white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  min-height: 556px;
  margin: -100px 32px 100px;
  padding: 32px 43px;
  min-width: 840px;
}

.cuTable {
  width: 100%;
  text-align: left;
  border-spacing: 0;
}

.cuTable td strong {
  font-family: 'RobotoMedium', sans-serif;
  font-weight: normal;
}

.cuTable th {
  padding: 26px 25px 13px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $gray;
  border-bottom: 4px solid $border-color-light;
  text-align: left;

  &:last-child {
    padding-right: 12px;
    text-align: right;
  }

  &:last-child:only-child {
    text-align: center;
  }
}

.cuTable td {
  height: 56px;
  padding: 6px 23px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: $text-color;

  &:last-child {
    padding-right: 12px;
    text-align: right;

    &.errorCol {
      text-align: left;
    }
  }

  &:last-child:only-child {
    text-align: center;
  }
}

.cuTable td.colCompany {
  text-align: left;
}

.cuTable th.colCompany {
  text-align: left;
}

.cuTable tr td.actionCell {
  text-align: right;
}

.cuTable tr:nth-child(2n) td {
  background: $gray-light;

}

.cuTable tr:hover td {
  background: $backgroud-light;
  cursor: pointer;
}

.cuTable tr:nth-child(2n):hover td {
  background: $backgroud-light;
}

.alignRight, th.alignRight {
  text-align: right;
}

.cuCol1 {
  width: 200px;
  text-align: right;
}

.cuCol2 {
  width: 100px;
  text-align: right;
}

.cuCol3 {
  width: 250px;
}

.cuCol4 {
  width: 250px;
}

.cuCol5 {
  width: 50%;
}

.noData {
  text-align: center;
}

.cuTable.noHover tr:hover td {
  background: transparent;
  cursor: default;
}

.cuTable.noHover tr:nth-child(2n):hover td {
  background: $gray-light;

  &.errorCol {
    background: $white;
  }
}

.cuTable tr:nth-child(2n) .errorCol {
  background: $white;
}

.error {
  color: $red;
}

th.alignRight, .alignRight {
  text-align: right;
}

.tableIcon {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  margin-right: 15px;
}

// styles for page HR
.cuTable .checkboxCol {
  width: 30px;
  padding-right: 0;

  label {
    margin-right: 0;
  }
}

.payrollCol {
  width: 238px;
}

.statusCol {
  width: 178px;
}

.tableFormItem {
  margin-bottom: 0;
}

.numberCol {
  width: 180px;
}

.nextCol {
  width: 65px;
}

.btnLink {
  display: inline-block;
  vertical-align: middle;

  img {
    display: block;
  }
}

.tableLink {
  vertical-align: baseline;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: $primary-color;
  font-size: 16px;
  line-height: 24px;
  font-family: RobotoMedium, sans-serif;
  cursor: pointer;
  outline: none;
}