.cHeader {
  padding: 15px 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;

  & .cuPosition {
    right: 0;
    top: 0;
  }
}

.logo {
  display: block;
}

.cRight {
  min-width: 167px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  position: relative;
}

.cNotification {
  position: relative;
  width: 27px;
  height: 30px;
}

.notificationCounter {
  position: absolute;
  background-color: #E02020;
  border-radius: 9px;
  right: 0;
  top: 0;
  color: #fff;
  height: 16px;
  min-width: 16px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 16px;
}

.chevronDown {
  margin-left: 10px;
}

.osText {
  color: #737373;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
}

.link {
  margin-left: 20px;
}

.cSelect {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.cRolesSwitcher {
  display: inline-block;
  margin: 0;
  padding-left: 0;

  & li {
    display: inline-block;
    margin-left: 80px;

    & a {
      text-decoration: none;
      color: #4C4C4C;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      &.active {
        font-weight: bold;
      }
    }
  }
}