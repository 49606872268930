@import 'src/styles/tables';

.cContent {
  margin-top: -50px;
}

.colAccountNumber {
  width: 200px;
}

.colSortCode {
  width: 180px;
}